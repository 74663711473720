import { IconLocationOutlined, TextField } from '@madpaws/design-system';
import DOMPurify from 'isomorphic-dompurify';
import getConfig from 'next/config';
import React, { useEffect, useRef, useState } from 'react';

import styles from './AddressAutocomplete.module.css';

import type { ChangeEvent, ReactElement } from 'react';
import type { PlaceSuggestion } from '~/common/types/search';

type Props = {
  description?: string;
  hasIcon: boolean;
  isLabelVisuallyHidden?: boolean;
  isMaxWithEnabled?: boolean;
  label: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  onSuggestionSelected: (value: string) => void;
  placeholder?: string;
  suggestions: PlaceSuggestion[] | null;
  value: string;
};

const formatSuggestion = (suggestionDescription: string, searchTerm: string): string => {
  const regexSearchTerms = new RegExp(
    `(${searchTerm
      .split(' ')
      .filter((term) => term)
      .join('|')})`,
    'ig'
  );
  return suggestionDescription.replace(regexSearchTerms, `<mark>$1</mark>`);
};

const { publicRuntimeConfig } = getConfig();

// TODO(DS-232): Replace with design-system combobox component
export const AddressAutocomplete = ({
  description,
  isLabelVisuallyHidden = false,
  label,
  name,
  value,
  suggestions,
  onChange,
  onSuggestionSelected,
  placeholder = 'Enter address or suburb',
  hasIcon,
  isMaxWithEnabled = false,
}: Props): ReactElement => {
  const [isAutocompleteOpen, setAutocompleteOpen] = useState(false);
  const nodeRef = useRef<HTMLDivElement>(null);

  const handleClick = (mouseEvent: MouseEvent): void => {
    if (!nodeRef.current?.contains(mouseEvent.target as Node)) {
      setAutocompleteOpen(false);
    }
  };

  const handleKeyEscape = (keyboardEvent: KeyboardEvent): void => {
    if (keyboardEvent.key === 'Escape') {
      setAutocompleteOpen(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event);
    setAutocompleteOpen(true);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('keyup', handleKeyEscape);
    return (): void => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('keyup', handleKeyEscape);
    };
  }, []);

  return (
    <div className={styles.root} ref={nodeRef}>
      <div>
        <TextField
          autoComplete="off"
          description={description}
          hasTighterSpacing
          icon={hasIcon ? <IconLocationOutlined /> : undefined}
          isLabelVisuallyHidden={isLabelVisuallyHidden}
          isTopDescription
          label={label}
          name={name}
          onChange={handleInputChange}
          onClick={(): void => setAutocompleteOpen(true)}
          placeholder={placeholder}
          type="search"
          value={value}
        />
      </div>
      {suggestions && isAutocompleteOpen ? (
        <div
          className={
            !isMaxWithEnabled
              ? `${styles.suggestions} ${styles.suggestionsMaxWidth}`
              : styles.suggestions
          }
        >
          <div>
            <ul>
              {suggestions.length > 0 ? (
                suggestions.map((item) => (
                  <li key={item.id}>
                    <button
                      aria-label={item.description}
                      className={styles.button}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(formatSuggestion(item.description, value)),
                      }}
                      onClick={(): void => onSuggestionSelected(item.description)}
                      type="button"
                    />
                  </li>
                ))
              ) : (
                <p>No results found</p>
              )}
            </ul>
            <div className={styles.vendorLogo}>
              <img
                alt="Powered by Google logo"
                height="18"
                loading="lazy"
                src={`${publicRuntimeConfig.staticPath}/images/powered-by-google-logo.png`}
                width="144"
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
