import { useLazyQuery } from '@apollo/client';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import useConstant from 'use-constant';

import { AddressAutocomplete } from '~/common/components/AddressAutocomplete/AddressAutocomplete';
import {
  GET_PLACE_SUGGESTIONS,
  GET_SEARCH_ADDRESS_DETAILS,
} from '~/common/components/AddressAutocomplete/queries';
import { isAddressValid } from '~/common/components/AddressAutocomplete/utils';

import { SEARCH_FILTERS_FORM_KEYS as KEYS } from '../../../constants';

import type { FormikValues } from 'formik';
import type { ChangeEvent, ReactElement } from 'react';
import type {
  AddressValues,
  LocationValues,
  PlaceSuggestion,
  SearchAddressDetails,
} from '~/common/types/search';

type LocationInputProps = {
  isLabelVisuallyHidden?: boolean;
  isMaxWithEnabled?: boolean;
  label?: string;
  name?: string;
  onAddressDetailsLoad?: (locationValues: LocationValues) => void;
  placeholder?: string;
  setFieldValue: (field: string, value: unknown) => void;
  values: FormikValues;
};

const DEFAULT_ADDRESS_VALUES: AddressValues = {
  lat: null,
  lng: null,
  postcode: '',
  stateName: '',
  suburb: '',
};

export const LocationInput = ({
  isLabelVisuallyHidden,
  isMaxWithEnabled,
  label = 'Location',
  name = 'location',
  onAddressDetailsLoad,
  placeholder,
  setFieldValue,
  values: { location },
}: LocationInputProps): ReactElement => {
  const [suggestions, setSuggestions] = useState<PlaceSuggestion[] | null>(null);
  const { values, validateForm, setFieldTouched } = useFormikContext<FormikValues>();

  const [loadAddressDetails] = useLazyQuery<{
    searchAddressDetails: SearchAddressDetails;
  }>(GET_SEARCH_ADDRESS_DETAILS, {
    fetchPolicy: 'no-cache',
    onCompleted: async ({ searchAddressDetails }) => {
      if (searchAddressDetails === null) {
        return;
      }

      const { lat, lng, postcode, stateName, suburb } = searchAddressDetails;

      await setFieldValue(KEYS.address, {
        lat,
        lng,
        postcode,
        stateName,
        suburb,
      });

      await setFieldValue(KEYS.query, values.location.query.replace(', Australia', ''));

      // Trigger external function to store location data
      if (onAddressDetailsLoad) {
        onAddressDetailsLoad({
          query: location.query,
          address: {
            lat,
            lng,
            postcode,
            stateName,
            suburb,
          },
        });
      }

      await validateForm();
    },
  });

  const [loadPlaceSuggestions] = useLazyQuery<{
    placeSuggestions: PlaceSuggestion[];
  }>(GET_PLACE_SUGGESTIONS, {
    onCompleted: ({ placeSuggestions }) => {
      setSuggestions(placeSuggestions);
    },
  });

  const invalidateAddressValues = async (): Promise<void> => {
    await setFieldValue(KEYS.address, DEFAULT_ADDRESS_VALUES);
  };

  const onSuggestionSelected = async (value: string): Promise<void> => {
    setSuggestions(null);

    await setFieldValue(KEYS.query, value);

    await loadAddressDetails({
      variables: {
        address: value,
      },
    });
  };

  const updateSuggestedPlaces = async (value: string): Promise<void> => {
    if (!value) {
      setSuggestions(null);
      return;
    }

    await loadPlaceSuggestions({
      variables: {
        input: value,
      },
    });
  };

  // define a single instance of debounced function per component
  const updatedSuggestedPlacesDebounced = useConstant(() =>
    AwesomeDebouncePromise(updateSuggestedPlaces, 400)
  );

  const onAddressChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    // reset error message for address object when query field is updated
    setFieldTouched(KEYS.address, false, false);

    const {
      target: { value },
    } = event;

    await setFieldValue(KEYS.query, value);
    await updatedSuggestedPlacesDebounced(value);

    if (isAddressValid(location.address)) {
      await invalidateAddressValues();
    }
  };

  return (
    <AddressAutocomplete
      hasIcon
      isLabelVisuallyHidden={isLabelVisuallyHidden}
      isMaxWithEnabled={isMaxWithEnabled}
      label={label}
      name={name}
      onChange={onAddressChange}
      onSuggestionSelected={onSuggestionSelected}
      placeholder={placeholder}
      suggestions={suggestions}
      value={location.query}
    />
  );
};
