import { useLargeFromMediaQuery } from '@madpaws/design-system';
import classnames from 'classnames';
import { useField } from 'formik';

import styles from './ErrorBoundary.module.css';

import type { ReactElement, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  fieldName: string;
};

const ErrorBoundary = ({ fieldName, children }: Props): ReactElement => {
  const [, { error, touched }] = useField(fieldName);
  const isLargeFromViewport = useLargeFromMediaQuery();

  const errors: { [key: string]: string } = {
    location: isLargeFromViewport ? 'Address/suburb required' : 'An address or suburb is required',
    service: isLargeFromViewport ? 'Pet service required' : 'A pet service is required',
    petTypes: isLargeFromViewport ? 'Pets required' : 'Pets are required',
    chronology: isLargeFromViewport ? 'Dates required' : 'Dates are required',
  };

  const errorMessageResolver = (): string =>
    errors[fieldName] || `Field "${fieldName}" is required`;

  return (
    <div className={classnames({ [styles.root]: !!error && touched })}>
      <>
        {children}
        {!!error && touched && (
          <div className={styles.error} data-error-message>
            {errorMessageResolver()}
          </div>
        )}
      </>
    </div>
  );
};

export { ErrorBoundary };
