import { gql } from '@apollo/client';

export const GET_SEARCH_ADDRESS_DETAILS = gql`
  query searchAddressDetails($address: String!) {
    searchAddressDetails(address: $address) {
      formattedAddress
      lat
      lng
      postcode
      stateName
      suburb
    }
  }
`;

export const GET_PLACE_SUGGESTIONS = gql`
  query placePredictions($input: String!) {
    placeSuggestions(input: $input) {
      id
      description
    }
  }
`;
