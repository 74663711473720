import type { AddressValues } from '~/common/types/search';

export const isAddressValid = (addressValues?: AddressValues): boolean => {
  if (!addressValues) {
    return false;
  }

  const { lat, lng, postcode, stateName, suburb } = addressValues;

  return !!lat && !!lng && !!postcode && !!stateName && !!suburb;
};
