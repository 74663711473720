import { useFormikContext } from 'formik';
import React from 'react';

import { trackEvent } from '~/components/analytics/analytics';
import { UPDATE_FILTER_LOCATION_EVENT_NAME } from '~/components/analytics/constants';

import { LocationInput } from './LocationInput/LocationInput';

import type { FormikValues } from 'formik';
import type { ReactElement } from 'react';

type LocationFieldProps = {
  isLabelVisuallyHidden?: boolean;
  isMaxWithEnabled?: boolean;
  label?: string;
  name?: string;
  placeholder?: string;
};

export const LocationField = ({
  label,
  name,
  isLabelVisuallyHidden,
  placeholder,
  isMaxWithEnabled,
}: LocationFieldProps): ReactElement => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const onAddressDetailsLoad = (): void => trackEvent(UPDATE_FILTER_LOCATION_EVENT_NAME);

  // used data-faux-role="formField" to have
  // consistent styling with the rest form fields
  return (
    <div data-faux-role="formField">
      <LocationInput
        isLabelVisuallyHidden={isLabelVisuallyHidden}
        isMaxWithEnabled={isMaxWithEnabled}
        label={label}
        name={name}
        onAddressDetailsLoad={onAddressDetailsLoad}
        placeholder={placeholder}
        setFieldValue={setFieldValue}
        values={values}
      />
    </div>
  );
};
